import styled from '@emotion/styled'

export const Head = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`

const Content = styled('section')`
  margin: 61px 0 20px;
  width: 100%;
  padding: 20px;

  h4,
  h5,
  p {
    display: block;
    clear: both;
    width: 100%;
  }

  svg {
    color: white;
    margin: 25px auto;
    width: 200px;
    height: 200px;
    display: block;
    position: relative;
  }

  button > svg {
    width: 24px;
    height: 24px;
    margin: 0 auto;
  }

  &.valid {
    background: green;
  }
  &.invalid {
    background: #d10000;
  }

  img.qr {
    width: calc(100vw - 20px);
    max-width: 300px;
    margin: auto;
    display: block;
    border: 10px solid #fff;
  }
  img.qrsmall {
    width: 150px;
    border: 2px solid #fff;
    float: left;
    margin: 0 10px 0 0;
  }

  div.code {
    font-weight: 600;
    text-align: center;
    margin: 10px auto;
    font-size: 20px;
  }
  .valid {
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    margin: 10px auto;
    color: green;
  }
  .invalid {
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    margin: 10px auto;
    color: red;
  }
  .text {
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    margin: 10px auto;
    color: white;
  }
`

const QRcode = styled('img')`
  width: 100%;
  min-width: 150px;
  cursor: ${(props) => (props['data-id'] ? 'pointer' : 'default')};
`

const ListItemStyle = styled('div')`
  display: block;
  overflow: hidden;
  max-width: 962px;
  min-width: 700px;
  padding-bottom: 25px;
  margin: 0 0 25px;
  border-bottom: 1px solid #e7e7e7;
  span {
    display: block;
    font-size: 20px;
    line-height: 41px;
    &.red {
      color: red;
      font-weight: 700;
    }
    &.green {
      color: green;
      font-weight: 700;
    }
  }
`

export { Content, QRcode, ListItemStyle }
