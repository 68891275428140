const api = (data, callback) => {
  let apiPath =
    window.location.hostname.indexOf('localhost') !== -1
      ? '//localhost:8888/index.php'
      : `//${window.location.hostname}/api/index.php`
  /* if (window.location.hostname.indexOf('192.168.2') !== -1) {
    apiPath = `//${window.location.hostname}:9080/index.php`
  } */

  /* const { userAgent, platform, language } = window.navigator
  Object.assign(data, {
    navigator: {
      userAgent,
      platform,
      language,
      screen: {
        width: window.innerWidth,
        height: window.innerHeight,
        mobile: typeof window.orientation !== 'undefined',
        orientation: window.orientation || 'desktop',
      },
    },
  }) */
  // console.log(data)
  let bearer = ''
  if (data && data.azureData && data.azureData.id) {
    bearer = data.azureData.id
  } else if (window.localStorage.getItem('azureid')) {
    bearer = window.localStorage.getItem('azureid')
  }
  fetch(apiPath, {
    method: 'POST',
    body: JSON.stringify(data),
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authentication: `Bearer ${bearer}`,
    }),
  })
    .then((res) => res.json())
    .then((response) => {
      // console.log('api response', response)
      if (callback) {
        callback(response)
      }
    })
    .catch((error) => {
      console.error('API Error:', error)
    })
}

export default api
