import React from 'react'
import { Content } from '../Styling'
import {
  FilterIcon,
  PlusIcon,
  ClockIcon,
  CircleSlashIcon,
  CheckCircleIcon,
  PlusCircleIcon,
  TrashIcon,
  XIcon,
  ShieldCheckIcon,
  ShieldSlashIcon,
  DownloadIcon,
} from '@primer/octicons-react'

import { BsQrCode } from 'react-icons/bs'

export default (props) => {
  const { firstname, lastname, role, access } = props.appstate.user
  const { feature } = props.appstate
  const userAccess = access ? JSON.parse(access) : []

  const getStatusColor = (status) => {
    switch (status) {
      case '2':
        return '#FEBC2E' // apple yellow
      case '1':
        return '#28C840' // apple green
      default:
        return '#ED1C25' // strabag red
    }
  }

  return (
    <Content>
      <div className="input-group mb-3 mx-auto">
        <h5>
          Guten Tag {firstname} {lastname},
        </h5>
      </div>

      {['request'].includes(role) && (
        <div className="input-group mb-3 mx-auto">
          <p>
            Sie haben bisher keinen Zugang zum STRABAG QR-Coder.
            <br />
            <br />
            Bitte wenden Sie sich an den Administrator der Anwendung,
            <br />
            Herrn Kai Schmoll der STRABAG Rail Fahrleitungen GmbH.
          </p>
        </div>
      )}

      {['declined'].includes(role) && (
        <div className="input-group mb-3 mx-auto">
          <p>
            Sie haben keinen Zugang zum STRABAG QR-Coder.
            <br />
            <br />
            Dieser wurde Ihnen durch den Administrator der Anwendung,
            <br />
            Herrn Kai Schmoll der STRABAG Rail Fahrleitungen GmbH, nicht
            gewährt.
          </p>
        </div>
      )}

      {['admin', 'user'].includes(role) && (
        <div className="input-group mb-3 mx-auto">
          <p>
            Nachfolgend finden Sie einen kurzen Überblick über den
            Funktionsumfang des QR-Coders der STRABAG Rail Fahrleitungen GmbH.
          </p>
        </div>
      )}

      {!feature['plans'] &&
        !feature['redirects'] &&
        ['admin', 'user'].includes(role) && (
          <>
            <div className="input-group mb-3 mx-auto">
              <h5>Code erstellen</h5>
              <p>
                Dort können neue Codes in einem festgelegten Format erstellt
                werden:
                <br />
                1. Die Kostenstelle: Vier Buchstaben (XXXX). Sie findet sich
                auch im Menü wieder sobald ein Code erstellt wurde.
                <br />
                2. Das Datum im Format (JJMMDD). Dies kann durch Klick in das
                Feld auch über eine Auswahl eingegeben werden.
                <br />
                3. Die Plannummer, die aus bis zu 25 frei wählbaren Zeichen
                bestehen kann.
                <br />
                Mit Klick auf [speichern] kann der Code übernommen werden und
                findet sich ab sofort in der Code Liste wieder.
              </p>
            </div>

            <div className="input-group mb-3 mx-auto">
              <h5>Code Liste</h5>
              <p>
                In der Code Liste finden Sie sowohl die gültigen als auch die
                ungültigen QR Codes und können diese über das Menü nach
                Kostenstelle sortieren.
                <br />
                Sie finden dort die Funktionen [QR Code ungültig / wieder gültig
                machen] um die Gültigkeit eines Codes, bzw. Bauplanes zu ändern.
                <br />
                Sie können die Bilddatei zur weiteren Verarbeitung downloaden
                sowie nicht mehr benötigte Codes löschen.
              </p>
            </div>

            <div className="input-group mx-auto">
              <h5>Code scannen</h5>
              <p>
                Ein Code kann nun in einen Bauplan übertragen werden und ist
                fortan mit jedem aktuellen Mobiltelefon lesbar.
                <br />
                Nach dem Scan öffnet sich eine Webseite und man erhält die
                Information ob es sich um einen gültigen oder ungültigen Code
                handelt.
              </p>
            </div>
          </>
        )}

      {(feature['plans'] || feature['redirects']) &&
        ['admin', 'user'].includes(role) && (
          <div id="accordion">
            {userAccess.includes('plans') && (
              <div className="card">
                <div className="card-header" id="headingTwo">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Baupläne
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <div className="input-group mb-3 mx-auto">
                      <h5>Seiten Aktionen</h5>
                      <div>
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <td style={{ width: '50px' }}>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-info"
                                  title="Neuen Code erstellen"
                                >
                                  <PlusIcon />
                                </button>
                              </td>
                              <td>
                                Über diesen Button kann ein neuer QR-Code
                                erstellt werden. (s.u. Dialog: Neuen QR-Code
                                erstellen)
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: '50px' }}>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-secondary"
                                  title="Neuen Code erstellen"
                                >
                                  <FilterIcon />
                                </button>
                              </td>
                              <td>
                                Über diesen Button kann die dargestellte Liste
                                von Codes gefiltert werden, sodass nur die Codes
                                von Interesse hier dargestellt werden.
                              </td>
                            </tr>
                            <tr>
                              <td style={{ width: '50px' }}>
                                <button
                                  type="button"
                                  className="btn btn-sm"
                                  title="Neuen Code erstellen"
                                >
                                  Suche
                                </button>
                              </td>
                              <td>
                                Über diese Texteingabe kann nach QR-Codes per
                                Volltextsuche gefiltert werden.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="input-group mb-3 mx-auto">
                      <h5>Zeilen Aktionen</h5>
                      <div>
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <td>
                                <button type="button" className="btn">
                                  <BsQrCode style={{ color: 'black' }} />
                                </button>
                              </td>
                              <td>
                                Code öffnen zum Download der SVG oder zum
                                direkten Kopieren in die Zwischenablage.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-success"
                                >
                                  <CheckCircleIcon />
                                </button>
                              </td>
                              <td>
                                Über diesen Button kann ein Code gültig gemacht
                                werden.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-warning"
                                >
                                  <ClockIcon />
                                </button>
                              </td>
                              <td>
                                Über diesen Button kann ein Code zu einer
                                Vorabversion gemacht werden.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-secondary"
                                  style={{
                                    background: getStatusColor('0'),
                                  }}
                                >
                                  <CircleSlashIcon />
                                </button>
                              </td>
                              <td>
                                Über diesen Button kann ein Code ungültig
                                gemacht werden.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-secondary"
                                >
                                  <TrashIcon />
                                </button>
                              </td>
                              <td>
                                Über diesen Button kann ein Code vollständig
                                gelöscht werden. Hierzu muss er vorher ungültig
                                gemacht worden sein.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-info"
                                  title="löschen"
                                >
                                  <PlusCircleIcon size={24} />
                                </button>
                              </td>
                              <td>
                                Über diesen Button kann eine neue Version inkl.
                                automatischem Versionssprung vom selben Code
                                erstellt werden.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="input-group mb-3 mx-auto">
                      <h5>Liste</h5>
                      <p>
                        In der Liste werden alle QR-Codes angezeigt wir im
                        Filterdialog gewählt.
                        <br />
                        Links der Ersteller m,it Erstellungsdatum, rechts der
                        zuletzt Änderungen vorgenommen hat und wann diese
                        gemacht wurden.
                      </p>
                    </div>

                    <div className="input-group mb-3 mx-auto">
                      <h5>Dialog: Neuen QR-Code erstellen</h5>
                      <p>
                        Dort können neue Codes in einem festgelegten Format
                        erstellt werden:
                        <br />
                        <b>Kostenstelle</b>: Vier Buchstaben (XXXX).
                        <br />
                        <b>Datum</b> im Format (JJMMDD). Dies kann durch Klick
                        in das Feld auch über eine Auswahl eingegeben werden.
                        <br />
                        <b>Plannummer</b>: die aus bis zu 60 frei wählbaren
                        Zeichen bestehen kann.
                        <br />
                        <b>Änderungsindex</b>: Dieser kann wahlweise mit 000, A
                        oder a gewählt werden und inkrementiert sich beim
                        Erstellen einer neuen Version entsprechend (001, B oder
                        b)
                        <br />
                        <b>Status</b>: Hier kann man wählen ob man einen
                        Freigegebenen Code (grün) oder eine Vorabversion (gelb)
                        erstellen möchte.
                        <br />
                        <b>Notiz</b>: Hier kann eine Freitext eingegeben werden,
                        der angezeigt wird, wenn man den Code später am mobilen
                        Endgerät dargestellt wird.
                        <br />
                        <br />
                        Mit Klick auf [weiter] kann eine Vorschau erzeugt
                        werden, die dann mit [speichern] in die Liste übernommen
                        werden kann. Erst nach dem Speichern ist der Code
                        einsatzbereit und kann gedruckt und auch gescanned
                        werden.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {userAccess.includes('redirects') && (
              <div className="card">
                <div className="card-header" id="headingThree">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Weiterleitungen
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    <div className="input-group mb-3 mx-auto">
                      <h5>Weiterleitungen</h5>
                      <div>
                        Dort finden Sie eine tabellarische Übersicht über alle
                        vorhandenen Weiterleitungen mit allen Details:
                        <br />
                        <br />
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <td style={{ width: '220px' }}>
                                <strong>ID</strong>
                              </td>
                              <td>
                                Eindeutiger Bezeichner, der sich auch im
                                Dateinamen der QR Code Datei wiederfindet.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Kategorie</strong>
                              </td>
                              <td>Kategorie der angelegten Weiterleitung.</td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Link</strong>
                              </td>
                              <td>
                                Der Link, der in der Weiterleitung enthalten
                                ist, kann hier ausprobiert werden.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Aufrufe</strong>
                              </td>
                              <td>
                                Die Anzahl, wie oft der QR-Code gescanned wurde.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Erstellt</strong>
                              </td>
                              <td>
                                Die Person und der Zeitpunkt der Erstellung.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Ungültig</strong>
                              </td>
                              <td>
                                Die Person und der Zeitpunkt der Invalidierung.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="input-group mb-3 mx-auto">
                      <h5>Aktionen</h5>
                      <div>
                        <table className="table table-striped">
                          <tbody>
                            <tr>
                              <td style={{ width: '220px' }}>
                                <button
                                  type="button"
                                  className="btn btn-info"
                                  style={{ width: '182px' }}
                                >
                                  Erstellen
                                </button>
                              </td>
                              <td>
                                Über diesen Button kann eine neue Weiterleitung
                                erstellt werden. (s.u. Dialog)
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  title="QR Code ungültig machen"
                                >
                                  <ShieldCheckIcon size={24} />
                                </button>
                              </td>
                              <td>
                                Über diesen Button kann ein Code ungültig
                                gemacht werden. Das bedeutet, wenn er gescanned
                                wird, landet man nicht mehr auf dem angegebenen
                                Link sondern auf http://www.fahrleitungsbau.de
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  title="QR Code wieder gültig machen"
                                >
                                  <ShieldSlashIcon size={24} />
                                </button>
                              </td>
                              <td>
                                Über diesen Button kann ein Code wieder gültig
                                gemacht werden. Die Weiterleitung zum
                                angegebenen Ziel wird dann wieder ausgeführt.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  title="download"
                                >
                                  <DownloadIcon size={24} />
                                </button>
                              </td>
                              <td>
                                Über diesen Button kann ein Code als PNG Datei
                                heruntergeladen werden. Dieser kann nun in
                                Druckprodukten weiterverwendet werden.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  title="löschen"
                                >
                                  <XIcon size={24} />
                                </button>
                              </td>
                              <td>
                                Über diesen Button kann ein Code endgültig
                                gelöscht werden. Dies kann nicht rückgängig
                                gemacht werden.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="input-group mx-auto mb-3">
                      <h5>Dialog Weiterleitung erstellen</h5>
                      <p>
                        In diesem Dialog werden die Informationen Kategorie,
                        Name und Internetadresse benötigt. <br />
                        Sind die Daten vollständig und korrekt eingegeben,
                        erscheint eine Vorschau des QR Codes, den Sie erstellen
                        möchten. Dieser wird jedoch erst funktionsfähig, nachdem
                        er gespeichert wurde.
                      </p>
                    </div>

                    <div className="input-group mx-auto">
                      <h5>Code scannen</h5>
                      <p>
                        Ein heruntergeladener Code kann nun in Druckprodukte
                        übertragen werden und ist fortan mit jedem aktuellen
                        Mobiltelefon lesbar. Bitte hier darauf achten, dass der
                        Code nicht zu klein wird, da dieser sonst nicht auf
                        allen Geräten funktioniert.
                        <br />
                        Nach dem Scan öffnet sich die Website, auf die man
                        weiterleiten möchte.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
    </Content>
  )
}
