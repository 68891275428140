import React from 'react'
import styled from '@emotion/styled'

const Footer = styled('footer')`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 18px;
  width: 100%;
  text-align: center;
  font-size: 11px;
  background: #e9ecef;
  border-top: 1px solid #e7e7e7;
`
export default () => {
  return (
    <Footer>
      V2.2.0 &copy; 2020 - {new Date().getFullYear()} STRABAG Rail Fahrleitungen
      GmbH
    </Footer>
  )
}
