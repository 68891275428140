import React, { Component } from 'react'

import { Content, ListItemStyle, QRcode } from '../Styling'
import api from '../Api'
import getSrcFromSvg from '../lib/getSrcFromSvg'
import previewSvg from './preview-svg.png'

// https://blog.tomayac.com/2020/03/20/multi-mime-type-copying-with-the-async-clipboard-api/
// https://async-clipboard-demo.glitch.me/

const copy = async (code) => {
  const img = document.getElementById(code)
  // console.log(img)
  // This assumes you have marked up images like so:
  // <img
  //    src="foo.svg"
  //    data-mime-type="image/svg+xml"
  //    alt="Foo">
  //
  // Applying this markup could be automated
  // (for all applicable MIME types):
  //
  // document.querySelectorAll('img[src*=".svg"]')
  // .forEach((img) => {
  //   img.dataset.mimeType = 'image/svg+xml';
  // });
  const mimeType = 'image/svg+xml' // img.dataset.mimeType
  // Always create a textual representation based on the
  // `alt` text, or based on the source code for SVG images.
  /* let text
  if (mimeType === 'image/svg+xml') {
    text = await toSourceBlob(img)
  } else {
    text = new Blob([img.alt], { type: 'text/plain' })
  } */

  // console.log(text)
  const clipboardData = {
    // 'text/plain': text,
  }
  // Always create a PNG representation.
  clipboardData['image/png'] = await toPNGBlob(img)
  // When dealing with a non-PNG image, create a
  // representation in the MIME type in question.
  if (mimeType !== 'image/png') {
    clipboardData[mimeType] = await toOriginBlob(img)
  }
  try {
    // eslint-disable-next-line no-undef
    await navigator.clipboard.write([new ClipboardItem(clipboardData)])
  } catch (err) {
    // Currently only `text/plain` and `image/png` are
    // implemented, so if there is a `NotAllowedError`,
    // remove the other representation.
    // console.warn(err.name, err.message)
    if (err.name === 'NotAllowedError') {
      const disallowedMimeType = err.message.replace(
        /^.*?\s(\w+\/[^\s]+).*?$/,
        '$1'
      )
      delete clipboardData[disallowedMimeType]
      try {
        // eslint-disable-next-line no-undef
        await navigator.clipboard.write([new ClipboardItem(clipboardData)])
      } catch (err) {
        throw err
      }
    }
  }
  // Log what's ultimately on the clipboard.
  // console.log(clipboardData)
}

// Draws an image on an offscreen canvas
// and converts it to a PNG blob.
const toPNGBlob = async (img) => {
  const canvas = new OffscreenCanvas(500, 500) // img.naturalWidth, img.naturalHeight)
  const ctx = canvas.getContext('2d')
  // This removes transparency. Remove at will.
  ctx.fillStyle = '#fff'
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  ctx.drawImage(img, 0, 0)
  return await canvas.convertToBlob()
}

// Fetches an image resource and returns
// its blob of whatever MIME type.
const toOriginBlob = async (img) => {
  const response = await fetch(img.src)
  return await response.blob()
}

// Fetches an SVG image resource and returns
// a blob based on the source code.
/* const toSourceBlob = async (img) => {
  const b64 = img.src.split('base64,')[1]
  // console.log(b64)
  // const response = await fetch(img.src)
  const source = img.getAttribute('data-svg') // btoa(b64) // await response.text()
  return new Blob([source], { type: 'text/plain' })
} */

const ListItem = (props) => {
  const {
    id,
    svg,
    code,
    date,
    valid,
    handleClick,
    handleDelete,
    handleDownload,
    handleCopy,
    invalidate,
    loadSVG,
  } = props
  let { user, modifier } = props

  if (user) {
    user.name = `${user.lastname}, ${user.firstname}`
  } else {
    user = { name: <i>[Benutzer gelöscht]</i> }
  }

  if (modifier) {
    modifier.name = `${modifier.lastname}, ${modifier.firstname}`
  } else {
    modifier = { name: <i>[Benutzer gelöscht]</i> }
  }

  const src = svg ? getSrcFromSvg(svg) : ''
  const SVG = svg ? (
    <QRcode size={200} src={src} alt={code} title={code} />
  ) : (
    <QRcode
      size={200}
      src={previewSvg}
      data-id={id}
      alt={code}
      title={code}
      onClick={loadSVG}
    />
  )

  const targetTime = new Date(date.replace(/-/g, '/'))
  const offsetTime =
    new Date(targetTime.getTime() - 60 * 1000).toLocaleDateString('de-DE', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }) + ' Uhr'

  let ioffsetTime = ''
  if (invalidate) {
    const itargetTime = new Date(invalidate.replace(/-/g, '/'))
    ioffsetTime =
      'seit ' +
      new Date(itargetTime.getTime() - 60 * 1000).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }) +
      ' Uhr'
  }

  return (
    <ListItemStyle>
      <div className="row">
        <div className="col-3 pt-2">{SVG}</div>
        <div className="col-9">
          <span>
            <b>{code}</b>
          </span>
          <span>
            Erstellt {offsetTime} von {user.name}
          </span>
          {!!valid && (
            <span className="green">
              gültig
              <br />
              &nbsp;
            </span>
          )}
          {!valid && modifier && (
            <span className="red">
              ungültig
              <br /> {ioffsetTime} durch {modifier.name}
            </span>
          )}
          {!!valid && (
            <button
              type="button"
              className="mt-3 btn btn-dark"
              data-id={id}
              data-valid={valid ? 1 : 0}
              onClick={handleClick}
            >
              QR Code ungültig machen
            </button>
          )}
          {!valid && (
            <button
              type="button"
              className="mt-3 btn btn-light"
              data-id={id}
              data-valid={valid ? 1 : 0}
              onClick={handleClick}
            >
              QR Code wieder gültig machen
            </button>
          )}
          <button
            type="button"
            className="mt-3 ml-3 btn btn-success"
            data-id={id}
            data-src={src}
            data-code={code}
            onClick={handleDownload}
          >
            QR Code download
          </button>
          {handleCopy && (
            <button
              type="button"
              className="mt-3 ml-3 btn btn-primary"
              data-id={id}
              data-src={src}
              data-code={code}
              onClick={handleCopy}
            >
              QR Code copy
            </button>
          )}
          <button
            type="button"
            className="mt-3 ml-3 btn btn-danger"
            data-id={id}
            data-valid={valid ? 1 : 0}
            onClick={handleDelete}
          >
            QR Code löschen
          </button>
        </div>
      </div>
    </ListItemStyle>
  )
}

export default class App extends Component {
  constructor(props) {
    // console.log({ props })
    super(props)
    this.state = {
      area: props.area,
      user: props.appstate.user,
      codes: [],
    }
  }

  componentDidMount() {
    api({ action: 'list' }, (response) => {
      const { codes } = response
      this.setState({ codes })
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { area } = nextProps
    if (area !== this.state.area) {
      this.setState({ area })
    }
  }

  handleClick = ({ currentTarget }) => {
    const id = currentTarget.getAttribute('data-id')
    const valid = currentTarget.getAttribute('data-valid') === '1' ? 0 : 1
    const { user } = this.state
    api({ action: 'set', id, valid, modifier_id: user.id }, (response) => {
      const { codes } = response
      // console.log(codes[0])
      this.setState({ codes })
    })
  }

  handleDelete = ({ currentTarget }) => {
    const id = parseInt(currentTarget.getAttribute('data-id'), 10)
    api({ action: 'code-delete', id }, () => {
      const codes = this.state.codes.filter((code) => code.id !== id)
      const area = 'all'
      this.setState({ codes, area })
    })
  }

  handleCopy = async ({ currentTarget }) => {
    // const src = currentTarget.getAttribute('data-src')
    api(
      { action: 'svg', id: currentTarget.getAttribute('data-id') },
      (response) => {
        this.setState(
          {
            codes: this.state.codes.map((code) =>
              code.id === parseInt(response.code.id, 10) ? response.code : code
            ),
          },
          () => {
            const code = response.code.code
              .replace(/\s+$/, '')
              .replace(/[^a-z0-9]/gi, '-')

            const svg = response.code.svg // .replace(/shape-rendering="crispEdges"/g, '')
            var dl = document.createElement('img')
            document.body.appendChild(dl) // This line makes it work in Firefox.
            dl.style.cssText = 'position: absolute; top: -2000px'
            dl.alt = code
            dl.id = code
            dl.crossOrigin = true
            dl['data-mime-type'] = 'image/svg+xml'
            dl.setAttribute('src', getSrcFromSvg(svg))
            dl.setAttribute('data-svg', svg)
            copy(code)
          }
        )
      }
    )
  }

  handleDownload = ({ currentTarget }) => {
    const src = currentTarget.getAttribute('data-src')
    if (!src) {
      api(
        { action: 'svg', id: currentTarget.getAttribute('data-id') },
        (response) => {
          this.setState(
            {
              codes: this.state.codes.map((code) =>
                code.id === parseInt(response.code.id, 10)
                  ? response.code
                  : code
              ),
            },
            () => {
              const code = response.code.code
                .replace(/\s+$/, '')
                .replace(/[^a-z0-9]/gi, '-')
              var dl = document.createElement('a')
              document.body.appendChild(dl) // This line makes it work in Firefox.
              dl.style.cssText = 'position: absolute; top: -2000px'
              dl.setAttribute('href', getSrcFromSvg(response.code.svg))
              dl.setAttribute('download', `${code}.svg`)
              dl.click()
            }
          )
        }
      )
      return
    }
    const code = currentTarget
      .getAttribute('data-code')
      .replace(/\s+$/, '')
      .replace(/[^a-z0-9]/gi, '-')
    // console.log({ src, code})

    var dl = document.createElement('a')
    document.body.appendChild(dl) // This line makes it work in Firefox.
    dl.style.cssText = 'position: absolute; top: -2000px'
    dl.setAttribute('href', src)
    dl.setAttribute('download', `${code}.svg`)
    dl.click()
  }

  handleLoadSVG = ({ currentTarget }) => {
    api(
      { action: 'svg', id: currentTarget.getAttribute('data-id') },
      (response) => {
        this.setState({
          codes: this.state.codes.map((code) =>
            code.id === parseInt(response.code.id, 10) ? response.code : code
          ),
        })
      }
    )
  }

  render() {
    const { codes, area, user } = this.state

    return (
      <Content>
        <h1>Bauplancodes</h1>
        <div className="mb-3">
          {!!codes.length &&
            codes.map((params) => {
              return area === 'all' || params.code.startsWith(area) ? (
                <ListItem
                  {...params}
                  key={params.code}
                  handleClick={this.handleClick}
                  handleDelete={this.handleDelete}
                  handleDownload={this.handleDownload}
                  handleCopy={
                    [17, 28, 30].includes(user.id) ? this.handleCopy : undefined
                  }
                  loadSVG={this.handleLoadSVG}
                />
              ) : null
            })}
          {!codes.length && (
            <h5>
              Keine Codes{' '}
              {area === 'all' ? '' : `mit der Kostenstelle ${area} `}vorhanden
            </h5>
          )}
        </div>
      </Content>
    )
  }
}
