import React, { Component } from 'react'

import { Content } from '../Styling'
import api from '../Api'

export default class App extends Component {
  constructor(props) {
    super(props)

    this.myMSALObj = null
  }

  componentDidMount() {
    this.myMSALObj = new window.Msal.UserAgentApplication(this.props.msal)
    this.myMSALObj.handleRedirectCallback(this.authRedirectCallBack)

    document.getElementById('SignIn').onclick = () => {
      this.myMSALObj.loginRedirect({
        scopes: ['user.read', 'email', 'profile'],
      })
    }

    if (window.location.hostname === 'localhost') {
      const azureid = window.localStorage.getItem('azureid')
      const section = window.localStorage.getItem('section')
      const area = window.localStorage.getItem('area')
      const user = window.localStorage.getItem('user')
      const cost = window.localStorage.getItem('cost')
      const feature = window.localStorage.getItem('feature')
      const categories = window.localStorage.getItem('categories')
      if (azureid && section && area && user && cost && feature && categories) {
        this.props.handleGoTo({
          section,
          area,
          user: JSON.parse(user),
          cost: JSON.parse(cost),
          feature: JSON.parse(feature),
          categories: JSON.parse(categories),
        })
      }
    }
  }

  signOut = () => {
    this.myMSALObj.logout()
    window.localStorage.clear()
  }

  authRedirectCallBack = (error, response) => {
    if (error) {
      console.log({ error })
    } else {
      if (response.tokenType === 'id_token') {
        const azureData = {
          id: response.account.accountIdentifier,
          givenName: response.account.name.split(' ')[0],
          surname: response.account.name.split(' ')[1],
        }
        // console.log('Azure Data', { response, azureData })
        api({ action: 'azure-login', azureData }, (response) => {
          const { error, section, area, user, cost, feature, categories } =
            response
          if (error) {
            this.props.handleGoTo({ error })
          } else {
            // console.log(response)
            if (window.location.hostname === 'localhost') {
              window.localStorage.setItem('azureid', azureData.id)
              window.localStorage.setItem('section', section)
              window.localStorage.setItem('area', area)
              window.localStorage.setItem('user', JSON.stringify(user))
              window.localStorage.setItem('cost', JSON.stringify(cost))
              window.localStorage.setItem('feature', JSON.stringify(feature))
              window.localStorage.setItem(
                'categories',
                JSON.stringify(categories)
              )
            }
            this.props.handleGoTo({
              section,
              area,
              user,
              cost,
              feature,
              categories,
            })
          }
        })
      }
    }
  }

  render() {
    return (
      <Content>
        <form style={{ maxWidth: '500px' }} className="mx-auto">
          <div className="container">
            <div className="leftContainer text-center">
              <p id="WelcomeMessage">Guten Tag, bitte melden Sie sich an.</p>
              <button type="button" id="SignIn" className="btn btn-primary">
                Anmelden über MS Azure
              </button>
            </div>
          </div>
        </form>
      </Content>
    )
  }
}
