import React from 'react'
import styled from '@emotion/styled'

import logo from './strabag-logo.png'

const Header = styled('header')`
  background-image: url(${props => props.logo});
  background-repeat: no-repeat;
  background-position: top right;
  position: fixed;
  top: 0;
  right: 10px;
  height: 61px;
  width: 145px;
  z-index: 2001;
`

export default () => {
  return <Header logo={logo} />
}
