import React, { Component } from 'react'

import Header from './Header'
import Navigation from './Navigation'
import Footer from './Footer'
import Home from './Home'
import Azure from './Azure'
import Create from './Create'
import List from './List'
import CreateRedirect from './CreateRedirect'
import ListRedirects from './ListRedirects'
import Find from './Find'
import User from './User'
import Construction from './Construction'
import ConstructionV3 from './Construction/v3'

import queryStringToJSON from './lib/queryStringToJSON'
import api from './Api'
import './App.css'

import 'jquery'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'

class App extends Component {
  constructor() {
    super()
    this.user = {}

    const mock = {
      // "error": "",
      // "cost": { "JGHJ": true, "JHDT": true, "JHEA": true, "JHEE": true, "JHEF": true, "JHEG": true, "JHEL": true, "JHEM": true, "JHEN": true, "JHEP": true, "JHEQ": true, "JHER": true, "JHES": true, "JHEU": true, "JHEY": true, "JHEZ": true, "JHFA": true, "JHFB": true, "JHFC": true, "JHFE": true, "JHFG": true, "JHFI": true, "JHFJ": true, "JHFK": true, "JHFL": true, "JHFO": true, "JHFP": true, "JHFR": true, "JHFS": true, "JHFT": true, "JHFW": true, "JHFY": true, "JHFZ": true, "JHGB": true, "JHGC": true, "JHGE": true, "JHGF": true, "JHGG": true, "JHGI": true, "JHGJ": true },
      // "user": { "id": 30, "azureid": "15a9e056-f7fe-4fb6-b955-55e7b9d96806", "lastname": "Bueth", "firstname": "Alexander", "signup": "2023-03-30 01:20:07", "role": "admin" },
      // "section": "home",
      // "area": "default"
    }

    this.state = Object.assign(
      {
        loading: true,
        error: '',
        info: '',
        section: 'init',
        area: 'start',
        valid: null,
        src: null,
        codes: [],
        user: null,
        feature: {},
      },
      queryStringToJSON(),
      mock
    )
  }

  componentDidMount() {
    const { section, code } = this.state
    // console.log('state', this.state)
    switch (section) {
      case 'find':
        api({ action: 'find', code }, (response) => {
          const { error, valid, src, code } = response
          if (error) {
            this.setState({ loading: false, error })
          } else {
            this.setState({ loading: false, valid, src, code })
          }
          // console.log('response', response)
        })
        break

      default:
        api({ action: 'init' }, (response) => {
          const { error, section, area, msal } = response
          if (msal) {
            window.localStorage.setItem('msal', msal)
          }
          if (error) {
            this.setState({ loading: false, error })
          } else {
            this.setState({
              loading: false,
              error: false,
              section,
              area,
            })
          }
        })
        return
    }
  }

  goTo = ({ section, area, error, info, user, cost, feature, categories }) => {
    if (section !== this.state.section) {
      window.localStorage.setItem('section', section)
    }
    if (area !== this.state.area) {
      window.localStorage.setItem('area', area)
    }
    this.setState(
      {
        section: section || this.state.section,
        area: area || this.state.area,
        cost: cost || this.state.cost,
        error: error || '',
        info: info || '',
        user: user !== false ? user || this.state.user : null,
        feature: feature || this.state.feature,
        categories: categories || this.state.categories,
      },
      () => {
        // const { section, area } = this.state
        // console.log('state', this.state)
      }
    )
  }

  render() {
    const { section, area, loading, error, info, code, valid, src } = this.state
    // console.log({ section, area, loading, error, info, code, valid, src })
    const msaljson = JSON.parse(window.localStorage.getItem('msal'))
    return (
      <div className="App">
        <Header />
        <Navigation
          appstate={this.state}
          handleGoTo={this.goTo}
          msal={msaljson}
        />

        {!loading && (
          <React.Fragment>
            {error && (
              <div
                style={{ maxWidth: '530px', margin: '80px auto -70px' }}
                className="row mx-auto"
              >
                <div className="col-12">
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                </div>
              </div>
            )}
            {info && (
              <div
                style={{ maxWidth: '530px', margin: '80px auto -70px' }}
                className="row mx-auto"
              >
                <div className="col-12">
                  <div className="alert alert-success" role="alert">
                    {info}
                  </div>
                </div>
              </div>
            )}
            {section === 'azure' && (
              <Azure area={area} handleGoTo={this.goTo} msal={msaljson} />
            )}
            {section === 'home' && (
              <Home appstate={this.state} area={area} handleGoTo={this.goTo} />
            )}
            {section === 'create' && (
              <Create
                appstate={this.state}
                area={area}
                handleGoTo={this.goTo}
              />
            )}
            {section === 'list' && (
              <List appstate={this.state} area={area} handleGoTo={this.goTo} />
            )}
            {section === 'find' && (
              <Find area={area} code={code} valid={valid} src={src} />
            )}
            {section === 'user' && (
              <User appstate={this.state} area={area} handleGoTo={this.goTo} />
            )}
            {section === 'create-redirect' && (
              <CreateRedirect
                appstate={this.state}
                area={area}
                handleGoTo={this.goTo}
              />
            )}
            {section === 'list-redirects' && (
              <ListRedirects
                appstate={this.state}
                area={area}
                handleGoTo={this.goTo}
              />
            )}
            {section === 'construction' && (
              <Construction
                appstate={this.state}
                area={area}
                handleGoTo={this.goTo}
              />
            )}
            {section === 'construction-v3' && (
              <ConstructionV3
                appstate={this.state}
                area={area}
                handleGoTo={this.goTo}
              />
            )}
          </React.Fragment>
        )}
        <Footer />
      </div>
    )
  }
}

export default App
