import React from 'react'

import { Content } from '../Styling'
import Checkmark from './Checkmark'
import Nope from './Nope'

export default props => {
	const { code, valid } = props // src
	// console.log('find', { code, valid, src })
	return (
		<Content className={valid ? 'valid' : 'invalid'} style={{ height: '100vh' }}>
			<div className="code">{code}</div>
      {!!valid && <><div className="text">Plan ist aktuell</div><Checkmark /></>}
      {!valid && <><div className="text">Plan ist veraltet</div><Nope /></>}
			{/* <img className="qr" src={src} /> */}
		</Content>
	)
}
