import React from 'react'

const SvgComponent = props => (
  <svg viewBox="0 0 320 320" {...props}>
    <path
      d="M128.775 265.136l146.36-214.392c6.772-9.926 4.215-23.459-5.706-30.236l-17.97-12.264c-9.921-6.772-23.464-4.221-30.236 5.706L100.171 191.268l-53.14-37.916c-9.779-6.978-23.366-4.71-30.345 5.075l-12.64 17.71c-6.978 9.779-4.705 23.366 5.075 30.345L98.8 270.467a21.219 21.219 0 0 0 29.975-5.331z"
      fill="currentColor"
    />
  </svg>
)

export default SvgComponent
