import React, { Component } from 'react'
import styled from '@emotion/styled'

const NavigationStyle = styled('nav')`
  position: fixed;
  top: 0;
  left: 0;
  min-height: 61px;
  right: 0;
  padding: 6px 160px 6px 10px;
  border-bottom: 1px solid #e7e7e7;
  z-index: 2000;

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f8f9fa+0,f8f9fa+50,f8f9fa+50,ffffff+100 */
  background: #e9ecef; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #e9ecef 0%,
    #ffffff 50%,
    #ffffff 80%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #e9ecef 0%,
    #ffffff 50%,
    #ffffff 80%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #e9ecef 0%,
    #ffffff 50%,
    #ffffff 80%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
`

export default class Navigation extends Component {
  constructor(props) {
    super(props)
    // console.log(props.appstate)
    this.myMSALObj = null
    this.state = props.appstate
  }

  componentDidMount() {
    // console.log(this.props.msal)
    if (this.props.msal) {
      this.myMSALObj = new window.Msal.UserAgentApplication(this.props.msal)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log({ nextProps })
    this.setState(nextProps.appstate)
  }

  handleClick = (ev) => {
    // console.log(ev.currentTarget)
    ev.preventDefault()
    const navToggle = document.querySelectorAll('.navbar-toggler')
    if (
      navToggle.length &&
      navToggle[0].getAttribute('aria-expanded') === 'true'
    ) {
      navToggle[0].click()
    }
    this.props.handleGoTo({
      section: ev.currentTarget.getAttribute('data-section'),
      area: ev.currentTarget.getAttribute('data-area') || null,
    })
  }

  handleLogout = (ev) => {
    ev.preventDefault()
    window.localStorage.clear()
    this.myMSALObj.logout()
  }

  render() {
    const { section, user, cost } = this.state
    const userAccess = user?.access ? JSON.parse(user.access) : []

    return user ? (
      <NavigationStyle className="navbar navbar-expand-lg navbar-light bg-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            <li className={`nav-item${section === 'home' ? ' active' : ''}`}>
              <a
                className="nav-link"
                href="/"
                data-section="home"
                onClick={this.handleClick}
              >
                Startseite / Hilfe
              </a>
            </li>

            {!this.state.feature['plans'] &&
              ['admin', 'user'].includes(user.role) && (
                <>
                  <li
                    className={`nav-item${
                      section === 'create' ? ' active' : ''
                    }`}
                  >
                    <a
                      className="nav-link"
                      href="/"
                      data-section="create"
                      data-area="plan"
                      onClick={this.handleClick}
                    >
                      Code erstellen
                    </a>
                  </li>
                  <li
                    className={`nav-item dropdown${
                      section === 'list' ? ' active' : ''
                    }`}
                  >
                    <span
                      style={{ cursor: 'pointer' }}
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Code Liste
                    </span>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <a
                        className="dropdown-item"
                        href="/"
                        data-section="list"
                        data-area="all"
                        onClick={this.handleClick}
                      >
                        Alle
                      </a>
                      <div className="dropdown-divider"></div>
                      {cost && Object.keys(cost).length && (
                        <div
                          style={{
                            height: 'calc(100vh - 200px)',
                            overflowX: 'hidden',
                            overflowY: 'auto',
                          }}
                        >
                          {Object.keys(cost).map((c) => (
                            <a
                              key={`key-${c}`}
                              className="dropdown-item"
                              href="/"
                              data-section="list"
                              data-area={c}
                              onClick={this.handleClick}
                            >
                              Kostenstelle {c}
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  </li>
                </>
              )}

            {this.state.feature['plans'] &&
              userAccess.includes('plans') &&
              ['admin', 'user'].includes(user?.role) && (
                <li
                  className={`nav-item dropdown${
                    ['create', 'list'].includes(section) ? ' active' : ''
                  }`}
                >
                  <span
                    style={{ cursor: 'pointer' }}
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Baupläne V1
                  </span>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <a
                      className="dropdown-item"
                      href="/"
                      data-section="create"
                      data-area="plan"
                      onClick={this.handleClick}
                    >
                      Code erstellen
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      href="/"
                      data-section="list"
                      data-area="all"
                      onClick={this.handleClick}
                    >
                      Alle
                    </a>
                    <div className="dropdown-divider"></div>
                    {cost && Object.keys(cost).length && (
                      <div
                        style={{
                          height: 'calc(100vh - 200px)',
                          overflowX: 'hidden',
                          overflowY: 'auto',
                        }}
                      >
                        {Object.keys(cost).map((c) => (
                          <a
                            key={`key-${c}`}
                            className="dropdown-item"
                            href="/"
                            data-section="list"
                            data-area={c}
                            onClick={this.handleClick}
                          >
                            Kostenstelle {c}
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                </li>
              )}

            {this.state.feature['construction'] &&
              userAccess.includes('construction') &&
              ['admin', 'user'].includes(user?.role) && (
                <li
                  className={`nav-item${
                    section === 'construction' ? ' active' : ''
                  }`}
                >
                  <a
                    className="nav-link"
                    href="/"
                    data-section="construction"
                    data-area="overview"
                    onClick={this.handleClick}
                  >
                    Baupläne V2
                  </a>
                </li>
              )}

            {this.state.feature['construction-v3'] &&
              userAccess.includes('construction-v3') &&
              ['admin', 'user'].includes(user?.role) && (
                <li
                  className={`nav-item${
                    section === 'construction-v3' ? ' active' : ''
                  }`}
                >
                  <a
                    className="nav-link"
                    href="/"
                    data-section="construction-v3"
                    data-area="overview"
                    onClick={this.handleClick}
                  >
                    Baupläne V3
                  </a>
                </li>
              )}

            {this.state.feature['redirects'] &&
              userAccess.includes('redirects') &&
              ['admin', 'user'].includes(user?.role) && (
                <li
                  className={`nav-item${
                    section === 'list-redirects' ? ' active' : ''
                  }`}
                >
                  <a
                    className="nav-link"
                    href="/"
                    data-section="list-redirects"
                    data-area="all"
                    onClick={this.handleClick}
                  >
                    Weiterleitungen
                  </a>
                </li>
              )}
          </ul>
          <ul className="navbar-nav justify-content-end">
            {user.role === 'admin' && (
              <li className={`nav-item${section === 'user' ? ' active' : ''}`}>
                <a
                  className="nav-link"
                  href="/"
                  data-section="user"
                  data-area="show"
                  onClick={this.handleClick}
                >
                  Benutzerverwaltung
                </a>
              </li>
            )}
            <li className="nav-item">
              <a className="nav-link" href="/" onClick={this.handleLogout}>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </NavigationStyle>
    ) : (
      <NavigationStyle className="navbar navbar-expand-lg navbar-light bg-light pl-3">
        <b>QR Coder</b>
      </NavigationStyle>
    )
  }
}
